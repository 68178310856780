<template>
  <div class="creator-container" :style="users.length < 4 ? { 'min-height': '100vh' } : { height: 'auto' }">
    <div class="header">
      <div class="header-title">
        <div class="main text">3D Creators</div>
      </div>
      <div class="search-editor">
        <v-row>
          <v-col lg="3" v-if="user.role !== 'meta'">
            <v-text-field v-model="search" prepend-icon="mdi-magnify" outlined class="text-field" dark solo dense
              background-color="transparent" placeholder="search by name...." @input="searchText">
              <v-icon slot="prepend" color="#ff7f3f" style="padding-left: 10px">mdi-magnify</v-icon>
            </v-text-field>
          </v-col>
          <v-col lg="3" v-else>
            <v-text-field v-model="search" prepend-icon="mdi-magnify" outlined class="text-field" dark solo dense
              background-color="transparent" placeholder="search by country...." @input="searchSpecialText">
              <v-icon slot="prepend" color="#ff7f3f" style="padding-left: 10px">mdi-magnify</v-icon>
            </v-text-field>
          </v-col>
          <v-col lg="1">
            <div @click="showFilter()"
              style="border-radius: 10px;border: 1px solid #ff7f3f !important; display: flex; justify-content: center; align-items: center; flex-direction: column; height: 40px; background: rgba(196, 196, 196, 0.18);cursor: pointer">
              <v-icon class="mt-0" color="#ff7f3f">{{ showFilters ?
                  'mdi-close' : 'mdi-filter-outline'
              }}
              </v-icon>
            </div>
          </v-col>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col lg="2" v-bind="attrs" v-on="on">
                <div @click="sortByName(sort.name)"
                  style="border-radius: 10px;border: 1px solid #ff7f3f !important; display: flex; justify-content: center; align-items: center; height: 40px; background: rgba(196, 196, 196, 0.18);cursor: pointer; color:white;">
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-if="sort.name">
                    mdi-sort-alphabetical-ascending</v-icon>
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-else>
                    mdi-sort-alphabetical-descending</v-icon>
                  Names
                </div>
              </v-col>
            </template>
            <span>Sort By Name</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col lg="2" v-bind="attrs" v-on="on">
                <div @click="sortByAssets(sort.asset)"
                  style="border-radius: 10px;border: 1px solid #ff7f3f !important; display: flex; justify-content: center; align-items: center; height: 40px; background: rgba(196, 196, 196, 0.18);cursor: pointer; color:white;">
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-if="sort.asset">
                    mdi-sort-alphabetical-ascending</v-icon>
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-else>
                    mdi-sort-alphabetical-descending</v-icon>
                  Assets
                </div>
              </v-col>
            </template>
            <span>Sort By Assets</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col lg="2" v-bind="attrs" v-on="on">
                <div @click="sortByRating(sort.rating)"
                  style="border-radius: 10px;border: 1px solid #ff7f3f !important; display: flex; justify-content: center; align-items: center; height: 40px; background: rgba(196, 196, 196, 0.18);cursor: pointer; color:white;">
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-if="sort.rating">
                    mdi-sort-alphabetical-ascending</v-icon>
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-else>
                    mdi-sort-alphabetical-descending</v-icon>
                  Ratings
                </div>
              </v-col>
            </template>
            <span>Sort By Ratings</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-col lg="2" v-bind="attrs" v-on="on">
                <div @click="sortByExperience(sort.exp)"
                  style="border-radius: 10px;border: 1px solid #ff7f3f !important; display: flex; justify-content: center; align-items: center; height: 40px; background: rgba(196, 196, 196, 0.18);cursor: pointer; color:white;">
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-if="sort.exp">
                    mdi-sort-alphabetical-ascending</v-icon>
                  <v-icon color="white" class="mr-2" style="cursor:pointer" v-else>
                    mdi-sort-alphabetical-descending</v-icon>
                  Experience
                </div>
              </v-col>
            </template>
            <span>Sort By Experience</span>
          </v-tooltip>
        </v-row>
      </div>
      <div class="search-filter" v-if="showFilters">
        <!-- <v-expansion-panels dark accordian="true" style="width: 95%">
          <v-expansion-panel class="expansion">
            <v-expansion-panel-header>
              Specialization filter
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="filter">
                <v-row>
                  <v-col lg="2" v-for="(special, index) in SPECIALIZATIONS" v-bind:key="index"
                    style="padding: 0px 12px 0px 12px !important;">
                    <v-checkbox :id="special" :label="`${special}`" :value="special" @change="filterData" dark
                      v-model="selectedTag"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel class="expansion mt-2">
            <v-expansion-panel-header>
              Country filter
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="filter">
                <v-row>
                  <v-col lg="2" v-for="(special, index) in country" v-bind:key="index"
                    style="padding: 0px 12px 0px 12px !important;">
                    <v-checkbox :id="special" :label="`${special}`" :value="special" @change="filterCountry" dark
                      v-model="selectedCountry"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
        <v-row style="width: 100%;">
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by Specializations</p>
              <v-select dark v-model="filterSections.specializationSel" :items="SPECIALIZATIONS" class="select-type"
                color="white" background-color="transparent" solo multiple chips clearable>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filterSections.specializationSel.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by Tools</p>
              <v-select dark v-model="filterSections.toolsSel" :items="tools" class="select-type" color="white"
                background-color="transparent" solo multiple chips clearable>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index < 1">
                    <span>{{ item }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ filterSections.toolsSel.length - 1 }} others)
                  </span>
                </template>
              </v-select>
            </div>
          </v-col>
          <v-col lg="4">
            <div class="form-group">
              <p>Filter by ratings</p>
              <v-select dark v-model="filterSections.ratings" :items="ratings" class="select-type" color="white"
                background-color="transparent" solo chips clearable multiple>
              </v-select>
            </div>
          </v-col>
        </v-row>
        <v-row style="width: 100%; justify-content: flex-end; padding-right: 0px;">
          <v-col lg="1">
            <v-btn class="btn button" @click="onApplyClick()">Apply</v-btn>
          </v-col>
          <v-col lg="1">
            <v-btn outlined class="button btn-secondary" style="margin-top: 10px;" @click="clear()">Clear</v-btn>
          </v-col>
        </v-row>
      </div>
      <div class="search-filter" v-if="showFilters">
        <v-expansion-panels dark accordian="true" style="width: 95%">
          <v-expansion-panel class="expansion">
            <v-expansion-panel-header>
              Specialization filter
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div class="filter">
                <v-row>
                  <v-col lg="2" v-for="(special, index) in SPECIALIZATIONS" v-bind:key="index"
                    style="padding: 0px 12px 0px 12px !important;">
                    <v-checkbox :id="special" :label="`${special}`" :value="special" @change="filterData" dark
                      v-model="selectedTag"></v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
    <!-- <div class="header-title mt-10">
      <div class="sub-title">Top Digital Creators</div>
    </div>
    <div class="tag-container">
      <div v-for="(tag, index) in tags" :key="index"
        :class="selectedTag.includes(tag.text) ? 'tag-box-selected' : 'tag-box'" @click="filterData(tag.text)">
        <p :class="
          selectedTag.includes(tag.text) ? 'tag-text-selected' : 'tag-text'
        ">
          {{ tag.text }}
        </p>
      </div>
    </div> -->
    <div class="asset-body mt-10" v-if="users.length !== 0">
      <v-row>
        <v-col lg="3" v-for="(user, index) in users" :key="index">
          <UserCard :user="user" style="margin-top: 10%; margin-bottom: 10%"></UserCard>
        </v-col>
      </v-row>
    </div>
    <div class="asset-body mt-10 text-center" v-else>
      <v-row>
        <v-col>
          <h1 class="no-data">No Users Found</h1>
        </v-col>
      </v-row>
    </div>
    <Loader :text="loaderText" :visible="isLoading"></Loader>
    <!-- <Filters :visible="showFilters" :filterData="selectedTag" @close="close" @filterData="filterData"
      :sortType="sortType" @sortData="sortData"></Filters> -->
  </div>
</template>
<script>
import Loader from "../components/Loader/Loader.vue";
import StatusModel from "../components/Modals/StatusModel.vue";
import { userObj } from "../services/user-service";
import UserCard from "../components/Common/UserCard.vue";
import { mapState } from "vuex";
import { userFilterMixins } from '../mixins/FilterUser';
import { SPECIALIZATIONS } from "../helpers/constants";
import { assetObj } from "../services/asset-service";

export default {
  name: "Creators",
  data() {
    return {
      selectedTag: [],
      tags: [
        {
          text: "All",
        },
        {
          text: "3D Rendering",
        },
        {
          text: "2D Animation",
        },
        {
          text: "Texturing",
        },
        {
          text: "NFT's",
        },
        {
          text: "Motion Graphics",
        },
      ],
      users: [],
      backupUsers: [],
      isLoading: false,
      loaderText: "Fetching User Data",
      isSuccess: false,
      successPopup: false,
      statusTitle: "",
      statusMessage: "",
      search: "",
      showFilters: false,
      sortType: ['Ascending', 'Descending'],
      selectedCountry: [],
      sort: {
        name: true,
        asset: true,
        rating: true,
        exp: true,
      },
    };
  },
  components: {
    Loader,
    StatusModel,
    UserCard,
  },
  methods: {
    async getAllDesigner() {
      this.isLoading = true;
      this.$store.dispatch('setGlobalPopup', true);
      const countryData = [];
      // if (this.allUsers.length === 0) {
      const data = await userObj.getUserByRole("designer");
      this.$store.dispatch('setAllUsers', data);
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        countryData.push(element.country);
      }
      this.country = [...new Set(countryData)];
      this.users = data;
      this.backupUsers = data;
      setTimeout(async () => {
        this.isLoading = false;
        this.$store.dispatch('setGlobalPopup', false);
        let value = [];
        // for (let index = 0; index < this.users.length; index++) {
        //   const element = this.users[index];
        //   const data = await assetObj.getAllLazyMintingAssetByUser(element.id);
        //   element.assetsCreated = data.length;
        //   value.push(element)
        // }
        // this.users = value;
      }, 2000);
      // } else {
      //   for (let index = 0; index < this.allUsers.length; index++) {
      //     const element = this.allUsers[index];
      //     countryData.push(element.country);
      //   }
      //   this.country = [...new Set(countryData)];
      //   this.users = this.allUsers;
      //   this.backupUsers = this.allUsers;
      //   setTimeout(async () => {
      //     this.isLoading = false;
      //     this.$store.dispatch('setGlobalPopup', false);
      //     let value = [];
      //     for (let index = 0; index < this.users.length; index++) {
      //       const element = this.users[index];
      //       const data = await assetObj.getAllLazyMintingAssetByUser(element.id);
      //       element.assetsCreated = data.length;
      //       value.push(element)
      //     }
      //     this.users = value;
      //   }, 1000);
      // }

    },
    close() {
      this.$store.dispatch('setGlobalPopup', false);
      this.showFilters = false;
    },
    showFilter() {
      // this.$store.dispatch('setGlobalPopup', true);
      this.showFilters = !this.showFilters;
    },
    searchText(text) {
      const allUser = this.backupUsers;
      if (text.length >= 3) {
        this.users = allUser.filter((user) => {
          return user.name.toLowerCase().includes(text.toLowerCase());
        });
      } else {
        this.users = this.backupUsers;
      }
    },
    searchSpecialText(text) {
      const allUser = this.backupUsers;
      if (text.length >= 3) {
        this.users = allUser.filter((user) => {
          if (user.country.toLowerCase().includes(text.toLowerCase())) {
            return true;
          }
        });
      } else {
        this.users = this.backupUsers;
      }
    },
    onApplyClick() {
      const filteredUsers = this.applyFilter(this.allUsers, this.backupUsers);
      this.users = filteredUsers;
    },
    clear() {
      this.clearFilter();
      this.users = this.backupUsers;
    },
    sortByAssets() {
      const allUser = this.backupUsers;
      this.sort.asset = !this.sort.asset;
      if (this.sort.asset) {
        this.users = allUser.sort((a, b) => {
          return a.assetCreated - b.assetCreated;
        });
      } else {
        this.users = allUser.sort((a, b) => {
          return b.assetCreated - a.assetCreated;
        });
      }
    },
    sortByName() {
      const allUser = this.backupUsers;
      this.sort.name = !this.sort.name;
      if (this.sort.name) {
        this.users = allUser.sort((a, b) => {
          return a.name.toLowerCase().trim().localeCompare(b.name.toLowerCase().trim());
        });
      } else {
        this.users = allUser.sort((a, b) => {
          if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) {
            return -1;
          }
          if (b.name.toLowerCase().trim() > a.name.toLowerCase().trim()) {
            return 1;
          }
          return 0;
        });
      }
    },
    sortByRating() {
      const allUser = this.backupUsers;
      this.sort.rating = !this.sort.rating
      if (this.sort.rating) {
        this.users = allUser.sort((a, b) => {
          return a.rating.skill - b.rating.skill;
        });
      } else {
        this.users = allUser.sort((a, b) => {
          return b.rating.skill - a.rating.skill;
        });
      }
    },
    sortByExperience() {
      const allUser = this.backupUsers;
      this.sort.exp = !this.sort.exp
      if (this.sort.exp) {
        this.users = allUser.sort((a, b) => {
          return a.yearsOfExperience - b.yearsOfExperience;
        });
      } else {
        this.users = allUser.sort((a, b) => {
          return b.yearsOfExperience - a.yearsOfExperience;
        });
      }
    },
    searchText(text) {
      const allUser = this.backupUsers;
      if (text.length >= 3) {
        this.users = allUser.filter((user) => {
          return user.name.toLowerCase().includes(text.toLowerCase());
        });
      } else {
        this.users = this.backupUsers;
      }
    }
  },
  created() {
    this.getAllDesigner();
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
      allUsers: (state) => state.allUsers,
    }),
  },
  mixins: [userFilterMixins]
};
</script>
<style scoped>
.creator-container {
  width: 100%;
  /* background-image: url("../assets/market/market_bg.svg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../assets/hue.svg");
  padding: 20px;
}

.header {
  width: 100%;
}

.header-title {
  height: 60px;
  position: relative;
}

.main {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 40px !important;
}

.sub-title {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-family: "Orbitron", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px !important;
}

.tag-container {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tag-box {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px 5px 10px;
  min-width: 10%;
}

.tag-box-selected {
  border: 1px solid var(--border-color);
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 5px 10px 5px 10px;
  min-width: 10%;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
}

.tag-text {
  margin: 0px 0px 0px 0px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 600;
  font-size: 16px;
  font-family: "Archivo", sans-serif;
  cursor: pointer;
}

.tag-text-selected {
  margin: 0px 0px 0px 0px;
  color: white;
  font-weight: 600;
  font-size: 16px;
  font-family: "Archivo", sans-serif;
  cursor: pointer;
}

.asset-body {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 6% !important;
}

.search-editor {
  width: 100%;
  height: 50px;
  border-color: var(--border-color) !important;
  margin: 20px 0px 20px 0px;
  padding: 5px;
}

.text-field {
  width: 100%;
  height: 40px !important;
  box-shadow: none !important;
  border-radius: 10px;
  border: 1px solid #ff7f3f !important;
  outline: none;
  color: #fff !important;
  font-family: "Archivo", sans-serif;
  background: rgba(196, 196, 196, 0.18);
}

.theme--light.v-text-field--outline.v-text-field--box {
  border: 1px solid #ff7f3f !important;
}

.v-icon {
  color: var(--border-color) !important;
}

.v-text-field .v-input__control .v-input__slot {
  min-height: auto !important;
  display: flex !important;
  align-items: center !important;
}

.search-filter {
  width: 100%;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
}

.expansion {
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: none !important;
}

.select-type {
  width: 100%;
  height: 50px;
  background: rgba(196, 196, 196, 0.1) !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  border: 1px solid #ff7f3f;
  outline: none;
  color: #fff !important;
  font-family: "DM Sans", sans-serif;
  padding: 5px;
  font-size: 12px !important;
}

.form-group {
  padding-left: 20px;
  margin-bottom: 20px;
}

.form-group>p {
  margin-bottom: 0px;
  margin-left: 0px;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.btn {
  background: linear-gradient(177.35deg, #ffbf3f -22.23%, #ff3f7f 97.79%);
  color: white !important;
  font-size: 18px !important;
  font-weight: 400 !important;
  width: 100%;
  margin-top: 10px;
  font-family: "Bebas Neue", cursive !important;
  letter-spacing: 0.8px !important;
}

.btn-secondary {
  border: 1px solid var(--border-color) !important;
  background-color: transparent !important;
  color: white !important;
}

.no-data {
  font-size: 25px;
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}
@media screen and (max-width: 680px) {
  .creator-container{
    padding-top: 30%;
  }
  .main{
    font-size: 30px !important;
  }
  .asset-body{
    margin-top: 50% !important;
  }
}
</style>
