<template>
  <div class="user-card">
    <div class="user-details">
      <div style="
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        ">
        <h3 class="name" v-if="userData.role !== 'team'">
          {{
              user.uid.substring(0, 8) +
              "..." +
              user.uid.substring(user.uid.length - 8, user.uid.length)
          }}
        </h3>
        <h3 class="name" v-else>
          {{ user.name }}
        </h3>
        <div class="rating" style="display: flex; justify-content: center; align-items: center; width: 100%; margin-right:22px">
          <v-row v-if="user.rating !== undefined" style="justify-content: center;">
            <v-col lg="1" v-for="n in 5" :key="n">
              <v-icon color="yellow darken-3" size="20">{{ n <= user.rating.skill ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
            </v-col>
          </v-row>
        </div>
      </div>
      <div class="stats" :style="
      userData.role !== 'meta' ? {'justify-content': 'space-between'} : {'justify-content': 'center'}
      ">
        <div class="follow-stats" v-if="userData.role !== 'meta'">
          <h3>{{ user.assetCreated === undefined ? 0 : user.assetCreated }}</h3>
          <p>Assets Created</p>
        </div>
        <div class="divider" v-if="userData.role !== 'meta'"></div>
        <div class="follow-stats">
          <h3>
            {{ user.yearsOfExperience }}
            <span style="font-size: 12px !important">Years</span>
          </h3>
          <p>Of Experience</p>
        </div>
      </div>
      <div class="soft-tags mt-5">
        <div class="tags" v-for="(tag, i) in user.specialization" :key="i"
          :style="i < 2 ? { display: 'flex' } : { display: 'none' }">
          <div class="tag">{{ tag }}</div>
        </div>
      </div>
      <v-btn class="btn" @click="viewProfile(user.uid)">View Profile</v-btn>
    </div>
    <div class="user-dp">
      <img :src="user.profile" />
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "UserCard",
  props: ["user"],
  methods: {
    viewProfile(uid) {
      this.$router.push(`/user/${uid}`);
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.user,
    }),
  },
};
</script>
<style scoped>
.user-card {
  width: 250px;
  height: 350px;
  border-radius: 20px;
  background: rgba(224, 224, 224, 0.14);
  backdrop-filter: blur(4.68316px);
  flex-basis: 20%;
  flex-shrink: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0px 20px 0px 0px;
  position: relative;
}

.user-details {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 35%;
}

.user-dp {
  position: absolute;
  top: -15%;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-dp>img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
}

.name {
  font-size: 18px;
  font-weight: bold;
  color: white;
  margin: 0px;
  font-family: "Archivo";
  text-align: center;
}

.role {
  font-size: 14px;
  font-weight: 400;
  color: rgba(196, 196, 196, 0.5);
  margin: 0px;
  font-family: "Archivo";
  text-transform: uppercase;
}

.stats {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5%;
}

.follow-stats {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  font-family: "Archivo";
  flex: 0.45;
}

.follow-stats>p {
  color: rgba(196, 196, 196, 0.5);
  font-weight: 200;
  font-family: "DM Sans";
  margin: 0px;
  font-size: 12px;
  text-transform: uppercase;
}

.follow-stats>h3 {
  color: white;
  font-size: 18px;
  font-weight: 600;
  margin: 0px;
}

.divider {
  height: 100%;
  width: 1px;
  background-color: white;
}

.btn {
  background: linear-gradient(97.3deg,
      #ffbf3f -2.55%,
      #ff7f3f 26.2%,
      #ff3f7f 98.17%) !important;
  font-weight: 400;
  font-size: 16px;
  font-family: "Bebas Neue", cursive;
  cursor: pointer;
  margin-top: 2%;
  color: white;
  letter-spacing: 0.9px !important;
  width: 70%;
}

.soft-tags {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.tag {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 5px;
  padding: 5px;
  color: white;
  font-size: 12px;
}

.tags {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
